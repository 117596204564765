<script setup lang="ts">
import { type CSSProperties } from 'vue'
import { clamp, copyToClipboard } from '../util'

export type PoiData = {
  x: number
  y: number
  icon?: string
  bg?: boolean
  size?: number
  static?: boolean
  position?: string
  dmonly?: boolean
  class?: string
}

export type MapData = {
  img: string
  width: number
  height: number
  class?: string
  imgclasses?: string
  nozoom?: boolean
  points: Record<string, PoiData>
}

const props = defineProps<{
  map: MapData
}>()

const mapEl = ref(null as HTMLElement | null)

const viewportX = ref(0)
const viewportY = ref(0)
const zoom = ref(1)
const fullscreen = ref(false)

const poisInViewport = computed<Record<string, PoiData>>(() => {
  const size = 1 / zoom.value
  const xmin = viewportX.value * props.map.width
  const xmax = (viewportX.value + size) * props.map.width
  const ymin = viewportY.value * props.map.height
  const ymax = (viewportY.value + size) * props.map.height

  return Object.fromEntries(
    Object.entries(props.map.points).filter(
      ([_name, poi]) => xmin <= poi.x && poi.x <= xmax && ymin <= poi.y && poi.y <= ymax
    )
  )
})

const imgStyle = computed<CSSProperties>(() => ({
  'transform-origin': '0 0',
  transform: `scale(${zoom.value}) translate(-${viewportX.value * 100}%, -${viewportY.value * 100}%)`,
  'aspect-ratio': `${props.map.width} / ${props.map.height}`,
}))

const mapStyle = computed<CSSProperties>(() => ({
  overflow: zoom.value > 1 && !fullscreen.value ? 'hidden' : 'visible',
  'aspect-ratio': `${props.map.width} / ${props.map.height}`,
  cursor: props.map.nozoom ? 'default' : 'zoom-in',
}))

const printCoords = (ev: MouseEvent) => {
  const percentX = ev.layerX / (ev.target as any).width
  const percentY = ev.layerY / (ev.target as any).height
  const x = (props.map.width * percentX).toFixed(0)
  const y = (props.map.height * percentY).toFixed(0)
  const output = `"": { x: ${x}, y: ${y}, icon, size: 1 }`
  copyToClipboard(output)

  console.log(output)
}

const handleScroll = (ev: WheelEvent) => {
  if (props.map.nozoom) return
  ev.preventDefault()

  // scrolling on a child element gives wrong layerX
  const mapRect = mapEl.value!.getBoundingClientRect()
  const elemX = (ev.clientX - mapRect.x) / mapRect.width
  const elemY = (ev.clientY - mapRect.y) / mapRect.height
  const centerX = viewportX.value + elemX / zoom.value
  const centerY = viewportY.value + elemY / zoom.value

  const direction = Math.sign(-ev.deltaY)
  // 12 zoom levels ought to be enough for anyone
  zoom.value = clamp(zoom.value * (1 + 0.25 * direction), 1, 3)
  const size = 1 / zoom.value

  viewportX.value = clamp(centerX - size * elemX, 0, 1 - size)
  viewportY.value = clamp(centerY - size * elemY, 0, 1 - size)
}
</script>

<template>
  <div
    v-if="map"
    ref="mapEl"
    :class="'interactive-map ' + map.class || ''"
    :style="mapStyle"
    @dblclick="printCoords"
    @wheel="handleScroll($event)"
  >
    <img :src="map.img" alt="" :class="map.imgclasses || ''" :style="imgStyle" :width="map.width" loading="lazy" />
    <PointOfInterest
      v-for="(poi, title) in poisInViewport"
      :key="`${title}${poi.x}${poi.y}`"
      :title="title"
      :poi="poi"
      :width="map.width"
      :height="map.height"
      :zoom="zoom"
      :viewport-x="viewportX"
      :viewport-y="viewportY"
    />
  </div>
</template>

<style scoped lang="postcss">
.interactive-map {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  --icon-color: #eee;
  --icon-background: #0008;

  .viewport {
    border: 1px solid red;
    position: absolute;
    pointer-events: none;
  }

  .papershadow {
    filter: drop-shadow(var(--card-shadow));
  }

  &.parchment {
    --icon-color: #000;
    --icon-background: #fffa;
    --background: #af9b88;
    --poi-blend-mode: normal;
  }

  &.inkarnate {
    --icon-color: #eee;
    --icon-background: #0008;
    --poi-blend-mode: normal;
    --background: #0005;
  }

  @media (max-width: 950px) {
    font-size: 0.6rem;
  }
}
</style>
